import React, { useState, useEffect } from 'react';
import './App.css';
import profilePicture from './images/profile.jpg'; 
import funLibsImage from './images/funlibs.PNG';   
import pinPointImage from './images/pinpoint.PNG'; 

function App() {
  const [theme, setTheme] = useState('dark'); // Theme state

  useEffect(() => {
    // Function to toggle theme
    const currentTheme = localStorage.getItem('theme') || 'light';
    setTheme(currentTheme);
    document.documentElement.setAttribute('data-theme', currentTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div className="App">
      {/* Theme Toggle Button */}
      <button className="theme-toggle" onClick={toggleTheme}>
        Switch to {theme === 'light' ? 'Dark' : 'Light'} Theme
      </button>

      {/* Hero Section */}
      <header className="hero">
        <div className="hero-content">
          <h1>Albretsen Tech</h1>
          <p>Personal Projects hosted on a Raspberry PI</p>
          <a href="#apps" className="scroll-button">Explore Apps</a>
        </div>
        <div className="hero-animation"></div>
      </header>

      {/* Profile Section */}
      <section className="profile">
        <img src={profilePicture} alt="Profile" className="profile-picture" />
        <h2>Asgeir Albretsen</h2>
        <p>
          Software developer specializing in mobile applications.
          <br />
          <a href="https://www.linkedin.com/in/asgeir-albretsen/" target="_blank" rel="noopener noreferrer">
            View LinkedIn Profile
          </a>
        </p>
      </section>

      {/* Apps Section */}
      <section id="apps" className="apps">
        <h2>Published Apps</h2>
        {/* Fun Libs App */}
        <div className="app-row">
          <div className="app-image-container">
            <img src={funLibsImage} alt="Fun Libs App" className="app-image" />
          </div>
          <div className="app-details">
            <h3>Fun Libs</h3>
            <p>An entertaining word game that's fun for everyone!</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.asgalb.FunLibs&hl=en"
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
            >
              Download on Google Play
            </a>
          </div>
        </div>

        <h2>Upcoming Apps</h2>
        {/* PinPoint App */}
        <div className="app-row reverse">
          <div className="app-image-container">
            <img src={pinPointImage} alt="PinPoint App" className="app-image" height={10}/>
          </div>
          <div className="app-details">
            <h3>PinPoint</h3>
            <p>Coming Soon: A new way to navigate your world.</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} albretsentech. Hosted on Raspberry Pi.</p>
      </footer>
    </div>
  );
}

export default App;
